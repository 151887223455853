import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpErrorResponse} from '@angular/common/http';
import {LoginService} from '../../login/login.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Tblusuarios} from '../../../models/tblusuarios';
import {AppSettings} from '../../../app.settings';

@Injectable()
export class EmpleadosService {
  private baseEndpoint: string =  AppSettings.baseEndpoint + '/empv';
  private baseEndpointRol: string =  AppSettings.baseEndpoint + '/rol';
  private baseEndpointUsr: string =  AppSettings.baseEndpoint + '/usr';

  constructor(private http: HttpClient,
              public loginService: LoginService) {}

  public listarEmpleadosPaginado(page: string, size: string, search: string, rol: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('search', search)
      .set('Idorgs', this.loginService.getOrgID().toString())
      .set('rol', rol);
    return this.http.get<any>(`${this.baseEndpoint}/pagina/?${params}`,{headers: this.loginService.addAuthorizationHeaders()}).pipe(
      catchError((e: HttpErrorResponse) => {
        this.loginService.noAuthorization(e);
        return throwError(this.loginService.validError(e));
      })
    );
  }

  public ver(id: number): Observable<Tblusuarios> {
    return this.http.get<Tblusuarios>(`${this.baseEndpointUsr}/${id}`,{headers: this.loginService.addAuthorizationHeaders()}).pipe(
      catchError((e: HttpErrorResponse) => {
        this.loginService.noAuthorization(e);
        return throwError(this.loginService.validError(e));
      })
    );
  }

  public crear(tblusuario: Tblusuarios): Observable<Tblusuarios> {
    tblusuario.usrIntiduseraudit = this.loginService.getUsrID();
    tblusuario.usrDatalta = new Date();
    tblusuario.usrDatmodificacion = new Date();
    tblusuario.tblEmpleado.empDatfin = new Date();
    tblusuario.tblEmpleado.empDatactualizacion = new Date();
    tblusuario.tblEmpleado.orgIntidorganizacion = tblusuario.usrIntdominioactual;
    tblusuario.usrBolsistema = false;
    tblusuario.usrBolactualizar = false;
    tblusuario.usrIntintentos = 0;
    //tblusuario.tblEmpleado.retIntidemptra = 1;

    return this.http.post<Tblusuarios>(`${this.baseEndpointUsr}/addusrges/${tblusuario.usrIntdominioactual}`, tblusuario,
      {headers: this.loginService.addAuthorizationHeaders()}).pipe(
      catchError((e: HttpErrorResponse) => {
        this.loginService.noAuthorization(e);
        return throwError(this.loginService.validError(e));
      })
    );
  }

  public registrar(tblusuario: Tblusuarios): Observable<Tblusuarios> {

    console.log(this.loginService.getUsrID());
    console.log(tblusuario.usrIntiduseraudit);
    tblusuario.usrBolactivo = false; //new
    tblusuario.usrBoleliminado = false;
    tblusuario.usrBolbloqueado = false;
    tblusuario.usrIntintentos = 0;
    tblusuario.usrDatalta = new Date();
    tblusuario.usrDatmodificacion = new Date();
    tblusuario.tblEmpleado.empDatfin = new Date();
    tblusuario.tblEmpleado.empDatactualizacion = new Date();
    tblusuario.usrBolsistema = false;
    tblusuario.usrBolactualizar = true;
    tblusuario.usrIntintentos = 0;
    //tblusuario.tblEmpleado.retIntidemptra = 1;

    return this.http.post<Tblusuarios>(`${this.baseEndpointUsr}/registrar`, tblusuario,
      { headers: this.loginService.addAuthorizationHeaders() }).pipe(
        catchError((e: HttpErrorResponse) => {
          this.loginService.noAuthorization(e);
          return throwError(this.loginService.validError(e));
        })
      );
  }

  public editar(tblusuario: Tblusuarios): Observable<Tblusuarios> {
    tblusuario.usrIntiduseraudit = this.loginService.getUsrID();
    tblusuario.usrDatmodificacion = new Date();
    tblusuario.tblEmpleado.empDatactualizacion = new Date();
    console.log('tblusuarioEmpleado: ' + JSON.stringify(tblusuario))
    console.log(`${this.baseEndpointUsr}/updusrges/${tblusuario.usrIntdominioactual}/${tblusuario.usrIntidusuario}`)
    return this.http.post<Tblusuarios>(`${this.baseEndpointUsr}/updusrges/${tblusuario.usrIntdominioactual}/${tblusuario.usrIntidusuario}`, tblusuario,
      {headers: this.loginService.addAuthorizationHeaders()}).pipe(
      catchError((e: HttpErrorResponse) => {
        this.loginService.noAuthorization(e);
        return throwError(this.loginService.validError(e));
      })
    );
  }

  

  public eliminar(id: number): Observable<void>{
    return this.http.delete<void>(`${this.baseEndpointUsr}/${id}`).pipe(
      catchError((e: HttpErrorResponse) => {
        this.loginService.noAuthorization(e);
        return throwError(this.loginService.validError(e));
      })
    );
  }

  public listarRoles(): Observable<any> {
    //return this.http.get<any>(this.baseEndpointRol,{headers: this.loginService.addAuthorizationHeaders()});


    return this.http.get<any>(`${this.baseEndpointRol}/getbyorg/${this.loginService.getOrgID().toString()}`, { headers: this.loginService.addAuthorizationHeaders() }).pipe(
      catchError((e: HttpErrorResponse) => {
        this.loginService.noAuthorization(e);
        return throwError(this.loginService.validError(e));
      })
    );
  }
}
