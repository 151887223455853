import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Tblempleados } from '../../models/tblempleados';
import { Catgeneros } from '../../models/catgeneros';
import { RegisterService } from './register.service';
import { HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import { LoginService } from '../login/login.service';
import { EmpleadosService } from '../catalogos/empleados/empleados.service';
import { Tblusuarios } from '../../models/tblusuarios';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
  @Output() registroCompletado = new EventEmitter<void>();
  angForm: UntypedFormGroup;
  error: any;
  empleado: Tblempleados = new Tblempleados();
  catGeneros: Catgeneros[];
  tblusuario: Tblusuarios = new Tblusuarios();
  emailPattern = "^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  existe: Boolean;
  loading: boolean;

  constructor(private fb: UntypedFormBuilder,
    private registerservice: RegisterService,
    private loginService: LoginService,
    private empleadoService: EmpleadosService,
    private router: Router) { }

  ngOnInit(): void {
    this.createForm(); //validaciones
  }

  createForm() {//validaciones
    this.angForm = this.fb.group({
      empStrnombre: ['', Validators.required],
      empStrpaterno: ['', Validators.required],
      empStrmaterno: ['', Validators.required],
      userName: ['', Validators.required],
      empStrcorreo: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      cgenIntidgenero: ['', Validators.required],
      empDatcumpleanios: ['', Validators.required],
    });
  }

  verificarinformacion(){
    this.loading = true;
    const correo = this.angForm.get('empStrcorreo').value;
    const username = this.angForm.get('userName').value;
    this.registerservice.verificarinfo(correo, username).subscribe(response => {
      const existeemail = response['existeemail'];
      const existeusr = response['existeusr'];
      if (existeusr ==true){
        this.loginService.logout();
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'El Nombre de usuario ya existe',
          showConfirmButton: true,
          confirmButtonColor: '#DC143C',
        })
        this.loading = false;
      } else if (existeemail==true){
        this.loginService.logout();
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'El correo electrónico ya existe',
          showConfirmButton: true,
          confirmButtonColor: '#DC143C',
        })
        this.loading = false;
      }else{
        this.tblusuario.usrIntiduseraudit = this.loginService.getUsrID();
        this.empleadoService.registrar(this.tblusuario).subscribe(usuario => {
          this.loginService.logout();
          Swal.fire('Nuevo', `Usuario ${usuario.usrStrusuario} Registrado con éxito. Se pondrán en contaco contigo por medio de tu correo electrónico`, 'success');
          this.registroCompletado.emit();
          this.loading = false;
        }, err => {
          this.loading = false;
          this.loginService.logout();
        })
      }
    },err => {
      this.loading = false;
      this.loginService.logout();
    });
  }

}
