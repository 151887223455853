import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
//import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

//YEISYSTEMS
import { LoginService } from './views/login/login.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './views/login/interceptors/token.interceptor';
import { AuthInterceptor } from './views/login/interceptors/auth.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {PipesModule} from '../pipes/pipes.module';
import {BsModalService, ModalModule,  BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';

//Autenticación
import { CommonModule, registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es-MX';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NavsComponent } from './views/navs/navs.component';
import {EmpleadosService} from './views/catalogos/empleados/empleados.service';
import { RegisterService } from './views/register/register.service';
import { ForgotPasswordService } from './views/forgotpassword/forgotpassword.service';
import { ForgotPasswordComponent } from './views/forgotpassword/forgotpassword.component';




const APP_CONTAINERS = [
  DefaultLayoutComponent
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
registerLocaleData(localeES, 'es-MX');

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    HttpClientModule,
    MatPaginatorModule,
    PipesModule,
    ModalModule.forRoot(),
    NgbModule, 
    MatPaginatorModule, 
    ReactiveFormsModule,
    YouTubePlayerModule
  ,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    NavsComponent,
  ],
  providers: [LoginService,
    RegisterService,
    ForgotPasswordService,
    BsModalService,
    MatPaginatorIntl,
    BsModalRef,
    EmpleadosService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
