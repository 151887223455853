import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Formatcero',
  pure: false
})
export class FormatceroPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    try{
      if(value<=0)
      return "0.0";
     else
      return value;
    }
    catch{
      return "0.0";
    }
    
  }
}
