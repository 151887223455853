import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { LoginService } from '../login.service';
import swal from 'sweetalert2';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public loginService: LoginService,
    private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

/*
    const token: string = localStorage.getItem('token');
    req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
*/

    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        console.log('AuthInterceptor: ' + JSON.stringify(e));

        /*
        //swal.fire('Error', `${e.error.error_description}`, 'warning');
        //this.loginService.logout();
        if (e.status == 401) {
          if (this.loginService.isAuthenticated()) {

            this.loginService.logout();
          }
          this.router.navigate(['/login']);
        }

        if (e.status == 403) {
          swal.fire('Acceso denegado', `Hola ${this.loginService.usuario.usrStrnombre} no tienes acceso a este recurso!`, 'warning');
          this.router.navigate(['/login']);
        }

        // si la sesión expiro
        if(this.loginService.isTokenExpired())
        {
          swal.fire('Sesión Expirada', `Hola ${this.loginService.usuario.usrStrnombre} tu sesión a expirado!`, 'warning');
          this.loginService.logout();
          this.router.navigate(['/login']);
        }
        return throwError(e);
*/

return throwError(e);
        //return throwError(this.loginService.validError(e));
      })
    );
  }
}
