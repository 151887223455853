import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Yesno',
  pure: false
})
export class YesnoPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    return value ? 'Si' : 'No';
  }
}
