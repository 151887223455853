export class Catroles {
  rolIntidcatrol: number;
  rolStrnombre: string = '';
  rolStrdescripcion: string = '';
  rolBolactivo: boolean;
  rolBoldefault: boolean;
  rolBolhereda: boolean;
  rolDatalta: Date;
  rolBoleliminado: boolean;
  rolDatmodificacion: Date;
  usrIntiduseraudit: number;
  orgStridorganizacion: string = '';
}
