import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../views/login/login.service';
import Swal from 'sweetalert2';
import {EmpleadosService} from '../../views/catalogos/empleados/empleados.service';
import {Tblusuarios} from '../../models/tblusuarios';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  usuario: string = '';
  dominio: string = '';
  avatar: string = '';
  //avatar2: string = '';
  tblusuario: Tblusuarios = new Tblusuarios();
  email: string = '';

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(private loginService: LoginService,
    private router: Router,
              private service: EmpleadosService){}

    ngOnInit(): void {
      this.usuario = this.loginService.usuario.usrStrusuario;
      this.dominio = this.loginService.usuario?.usrDominio;
      this.service.ver( this.loginService.usuario?.usrIntidusuario).subscribe(usuario => {
        this.loginService.guardarUsuarioLogin(usuario);
        this.validarAvatar()
      }, err => {
        Swal.fire('Error:', `${err}`, 'error');
      });
    }

  logout(){
    console.log('bye');
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

  editarPerfil(){
    //console.log(this.avatar)
    this.router.navigate(['/catalogos/usuarios/form/',this.loginService.getUsrID()]);
  }

  FAQS(){
    this.router.navigate(['/catalogos/faqs']);
  }

  validarAvatar(){
    //console.log('Empleado:: ' + JSON.stringify(this.loginService.usuario.tblEmpleado));
    if (this.loginService.usuario.tblEmpleado.empStrurlimg == null || this.loginService.usuario.tblEmpleado.empStrurlimg == ''){
      this.avatar = "assets/img/avatars/profile.jpg"
      this.email = '';
    } else{
      this.avatar = this.loginService.usuario.tblEmpleado.empStrurlimg;
      this.email = this.loginService.usuario?.tblEmpleado.empStrcorreo; // el modelo no trae nada en el login
    }
  }

}
