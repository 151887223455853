import {Tblempleados} from './tblempleados';
import {Catroles} from './catroles';

export class Tblusuarios {
  usrIntidusuario: number;
  usrStrusuario: string;
  usrStrnombre: string; //eliminar
  usrStrpassword: string;
  usrStremail: string;
  usrIntintentos: number;
  usrBolactualizar: boolean;
  usrDatalta: Date;
  usrDatmodificacion: Date;
  usrBolactivo: boolean;
  usrBolsistema: boolean;
  usrIntiduseraudit: number;
  usrIntdominioactual: number;
  empIntidempleado: number;
  usrAvatar: string;
  //rolIntidcatrol: number;

  rol: string;
  refresh_token: string;
  access_token: string;
  expires_in: number;

  catRole: Catroles;
  tblEmpleado: Tblempleados;

  usrBolbloqueado: boolean;
  usrBoleliminado: boolean;

  usrBolunicavez: boolean;
  usrInttotalcal: number;
  usrIntmaximocal: number;
  usrBolsinintentos: boolean;
  usrDominio: string;

  constructor() {
    this.catRole = new Catroles();
    this.tblEmpleado = new Tblempleados();
  }
}
